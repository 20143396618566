<template>
  <div>
    <div class="row">

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Fault Sites Report</h5>

            <div class="row g-3">
              <div class="col-md-6">
                <label for="faultSitesReportClient" class="form-label">Client</label>
                <Multiselect class="form-control form-select" style="background-color: rgb(0 0 0 / 15%) !important;" v-model="report.client_id" id="faultSitesReportClient" valueProp="id" label="name" :searchable="true" :options="clients"></Multiselect>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-12">
                <SubmitButton :clicked="generating" class="btn btn-primary float-end" @click="generateReport" text="Generate Report"/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div v-if="!generating && report.results.length > 0" class="row">
      <div class="col-12">
        <div class="card radius-10">
          <div class="row row-cols-1 row-cols-md-2 row-cols-xl-2 row-group g-0">
            <div class="col">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <h5 class="mb-0">{{report.results.length}}</h5>
                </div>
                <div class="progress my-3" style="height:3px;">
                  <div class="progress-bar" role="progressbar" style="width: 55%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center text-white">
                  <p class="mb-0">Faulty Sites</p>
                  <!--              <p class="mb-0 ms-auto">+4.2%<span><i class="bx bx-up-arrow-alt"></i></span></p>-->
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <h5 class="mb-0">{{totalOfflineDevices}}</h5>
                </div>
                <div class="progress my-3" style="height:3px;">
                  <div class="progress-bar" role="progressbar" style="width: 55%" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <div class="d-flex align-items-center text-white">
                  <p class="mb-0">Offline Devices</p>
                  <!--              <p class="mb-0 ms-auto">+5.2%<span><i class="bx bx-up-arrow-alt"></i></span></p>-->
                </div>
              </div>
            </div>
          </div><!--end row-->
        </div>
      </div>

    </div>

    <div v-if="!generating && report.results.length > 0" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">

            <div class="alert border-0 border-info border-start border-5 alert-dismissible fade show">
              <div class="text-white">Click on the devices offline column to view the devices which have failed their pings!</div>
            </div>

            <table class="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Client</th>
                  <th>Site</th>
                  <th>Faulty Since</th>
                  <th>Devices Offline</th>
                  <th>Health</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="site in report.results" :key="'report-entry-' + site.id">
                  <td>{{site.id}}</td>
                  <td>{{site.client_name}}</td>
                  <td><a href="javascript:void(0);" @click="$router.push({name: 'admin.sites.view', params: {id: site.id}})">{{site.name}} <i class='bx bx-link-external'></i></a></td>
                  <td><Time v-if="site.offline_since" :datetime="site.offline_since" format="dd/MM/yyyy HH:mm"/><span v-else>-</span></td>
                  <td><a href="javascript:void(0);" @click="selectSite(site)">{{site.offline_nodes || 0}} / {{site.total_monitored_nodes || 1}}</a></td>
                  <td>
                    <div class="progress mb-3" style="height:7px;">
                      <div class="progress-bar" :class="{'bg-success': site.health >= 80, 'bg-warning': site.health >= 50 && site.health < 80, 'bg-danger': site.health > 0 && site.health < 50, 'bg-dark': site.health <= 0}" role="progressbar" :style="`width: ${site.health}%`" :aria-valuenow="site.health" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>

    <div v-if="generating" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <p class="text-white font-24 mb-0 fw-bold">{{report.message}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- MODALS -->
    <div class="modal fade" id="viewOfflineDevicesModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Offline Devices - {{report.site?.name}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body" style="max-height: 500px;">
            <div class="row">

              <table class="table align-middle mb-0">
                <thead class="table-light">
                <tr>
                  <th style="width: 5%" scope="col">Type</th>
                  <th style="width: 17%" scope="col">Product</th>
                  <th style="width: 8%; max-width: 15ch;" scope="col">Address</th>
                  <th style="width: 1%" scope="col"></th>
                </tr>
                </thead>
                <tbody>
                <tr :class="{'bg-light': device.showing}" v-for="device in report.devices" :key="'device-' + device.id">
                  <td>{{device.node_type}}</td>
                  <td>{{device.node_model}}</td>
                  <td>{{device.address}}</td>
                  <td>
                    <button type="button" class="btn btn-sm btn-light showCredentials" @click="loadCredentials(device)"><i class="bx bx-key me-0"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>

              <table v-if="report.credentials.length > 0" class="table mb-0 table-hover mt-2">
                <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Username</th>
                  <th scope="col">Password</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="credential in report.credentials" :key="'credential-' + credential.id">
                  <td>{{credential.name}}</td>
                  <td>{{credential.username}}</td>
                  <td>{{credential.password}}</td>
                </tr>
                <tr v-if="report.credentials.length < 1">
                  <td colspan="4">No Credentials</td>
                </tr>
                </tbody>
              </table>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeViewOfflineDevicesModal'>Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global $ */
import Multiselect from "@vueform/multiselect";
import SubmitButton from "../../../../components/SubmitButton";
import axios from "axios";
import Time from "../../../../components/Time";
export default {
  name: "admin.reports.FaultySites",
  components: {
    Time,
    Multiselect,
    SubmitButton
  },
  data(){
    return {
      clients: [],
      generating: false,
      report: {
        client_id: null,
        message: "Loading faulty sites...",
        results: [],
        site: null,
        devices: [],
        credentials: []
      },
    }
  },
  mounted(){
    this.loadClients();
  },
  methods: {
    loadClients(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/client/list`)
        .then(response => {
          this.clients = response.data.clients;
        })
        .catch(error => {
          this.$error("Failed to load clients", error);
        })
    },
    generateReport(){
      this.generating = true;
      axios.get(`${process.env.VUE_APP_API_URL}/v1/reports/faultysites${this.report.client_id ? ("?client_id=" + this.report.client_id) : ""}`)
      .then(response => {
        if(response.data.success){
          response.data.sites.forEach((s) => {
            s.health = parseFloat(100 - (s.offline_nodes || 0) / (s.total_monitored_nodes || 1) * 100).toFixed(2);
          })
          this.report.results = response.data.sites
        }
      })
      .catch(error => {
        this.$erorr("Failed to load faulty site report", error);
      })
      .finally(() => {
        this.generating = false;
      })
    },
    selectSite(site){
      this.report.site = site;
      this.report.devices = [];
      this.report.credentials = [];
      axios.get(`${process.env.VUE_APP_API_URL}/v1/sites/${site.id}/offlinenodes`)
      .then(response => {
        this.report.devices = response.data.nodes;
        $('#viewOfflineDevicesModal').modal('toggle');
      })
      .catch(error => {
        this.$error("Failed to load devices", error);
      });
    },
    loadCredentials(device){
      this.report.devices.forEach((d) => {
        d.showing = false;
      });
      device.showing = true;
      axios.get(`${process.env.VUE_APP_API_URL}/v1/nodes/${device.id}/credentials`)
        .then(response => {
          this.report.credentials = response.data.credentials;
        })
        .catch(error => {
          this.$error("Failed to load device credentials.", error);
        })
    }
  },
  computed: {
    totalOfflineDevices(){
      let count = 0;
      this.report.results.forEach((r) => {
        count += r.offline_nodes || 0;
      })
      return count.toLocaleString();
    }
  }
}
</script>

<style scoped>

</style>

<style src="@vueform/multiselect/themes/default.css"></style>